.market-selector {
  margin: 8px auto 0;
  font-size: smaller;
}

p.market-selector-title {
  font-weight: 600;
  margin-bottom: 8px;
}

p.market-selector-footnote {
  text-align: right;
  font-size: smaller;
  color: dimgray;
  font-weight: 400;
  margin: 4px 4px 8px;
}
