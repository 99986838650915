td.update-time {
  min-width: 300px;
}

.vmce-status {
  margin: 10px 0 22px;
}

.vmce-status-updating {
  color: white;
  background-color: orange;
  padding: 2px 12px;
  margin: 0 12px;
}

.vmce-status-uptodate {
  color: white;
  background-color: seagreen;
  padding: 2px 12px;
  margin: 0 12px;
}
