.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-header {
  background-color: lightgray;
  flex-direction: column;
  align-items: center;
  color: black;
}

.scrollarea {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.MuiTablePagination-selectLabel {
  margin-bottom: 0;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0;
}

.mpi-loading-bar {
  position: fixed !important;
  width: 100%;
  z-index: 1000 !important;
}

@keyframes blink {
  0% { color: red; }
  100% { color: green; }
}
@-webkit-keyframes blink {
  0% { color: red; }
  100% { color: black; }
}
.blink {
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  animation: blink 1s linear infinite;
  font-weight: bold;
}
