div.models-input-panel {
  display: flex;
  padding-bottom: 16px;
}

div.models-input-panel > .add-release-model {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: auto;
}

div.release-model-list-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 2px 2px;
  padding-bottom: 16px;
}

.release-model-chip {
  min-width: 90px;
}
