div.export-page {
    margin: 0 auto;
    width: 80%;
}

div.checked-markets {
    text-align: right;
    font-weight: bold;
}

label#check-all {
    min-width: 150px;
}

div.markets {
    margin-top: 8px;
    padding-top: 4px;
    border-top: 1px solid #d3d3d3;
}

label.market {
    width: 12%;
    display: inline-block;
    text-align: left;
}

span#number-of-markets-checked {
    margin-right: 20px;
}

div.market-line {
    margin: 6px 0;
}

input.checkbox {
    margin-right: 4px;
}

button#export-btn {
    margin-top: 20px;
    padding: 10px 40px;
}
