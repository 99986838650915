table.mpi, th.mpi, td.mpi {
  border: 1px solid black;
}

td.clickable {
  text-align: center;
  cursor: pointer;
}

table.mpi, tbody.mpi, tr.mpi {
  height: 80px;
  border-collapse: collapse;
}

th.mpi {
  width: 80px;
  min-width: 70px;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  background: lightgray;
  opacity: 100%;
  z-index: 100;
}

.cell-model-id {
  background-color: lightpink;
}

.cell-model-source {
  background-color: darkseagreen;
}

.cell-model-domain {
  background-color: lightyellow;
}

.cell-model-present {
  background-color: lightskyblue;
}

td.mpi {
  padding: 0 2px;
}

td.error {
  background: orangered;
}

td.missing {
  background: indianred;
}

td.warn {
  background: orange;
}

.header {
  text-align: center;
}

.loader {
  margin: 22px auto;
  border: 16px solid lightgray;
  border-top: 16px solid cornflowerblue;
  border-radius: 50%;
  width: 88px;
  height: 88px;
  animation: spin 2s linear infinite;
}

.data-table {
  font-size: small;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.additional-info {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.additional-info .additional-info-text {
  visibility: hidden;
  width: 120px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.additional-info .additional-info-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.additional-info:hover .additional-info-text {
  visibility: visible;
}

div.market-table {
  width: 100%;
}
