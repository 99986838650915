p.language-selector-title {
  font-weight: 600;
  font-size: smaller;
  margin-bottom: 8px;
}

.MuiTableCell-root {
  font-size: smaller !important;
  padding: 6px 8px !important;
}

.table-cell-datetime {
  min-width: 90px;
}

.clickable {
  cursor: pointer;
}

div.table-cell-model-type-year {
  position: relative;
}

.table-cell-model-info {
  color: dimgray;
  font-size: .8rem !important;
  position: absolute;
  top: -8px;
  right: -10px;
  z-index: 10;
}
