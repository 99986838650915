.form-control-dark {
  border-color: var(--bs-gray);
}

.form-control-dark:focus {
  border-color: #fff;
  box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
}

.text-small {
  font-size: 85%;
}

.dropdown-toggle:not(:focus) {
  outline: 0;
}

button.stage-button {
  width: 110px !important;
}

div.menu-bar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
}

div.feature-component {
  display: flex;
  flex: 1;
  overflow: hidden;
}
